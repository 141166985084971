<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
         <!-- <v-icon
            @click="pushRouteToView('employees')"
            class="mr-4"
            style="cursor: pointer"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn
              small
               @click="pushRouteToView('employees')"
              class="mr-4 btn-back"
            >
              <v-icon> mdi-arrow-left </v-icon>
              Voltar
            </v-btn>
          <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
          Dados do Funcionário
          <v-spacer></v-spacer>
          <v-btn outlined class="btn-del"
                 @click="dialogDeleteEmployee=true">
            <v-icon left>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn outlined
                 :class="`${employee.active? 'btn-inactive': 'btn-active'} mx-2`"
                 @click="dialogActiveInactive=true">
            <span v-if="employee.active"><v-icon left>mdi-diameter-variant</v-icon> Inativar</span><span v-else><v-icon
            left>mdi-checkbox-marked-circle-outline</v-icon> Ativar</span>
          </v-btn>
          <v-btn
            color="#3A33E8"
            dark
            :to="{ name: 'employeeEdit', params: { id: employee.id } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col xl="8" lg="8" md="8" sm="12">
              <b><v-icon>mdi-account</v-icon> Nome:</b> {{ employee.name }}
              </v-col>
            <v-col xl="4" lg="4" md="4" sm="12">
              <b><v-icon>mdi-card-account-details-outline</v-icon> CPF:</b> {{ employee.cpf }}
              </v-col>
              </v-row>
              <v-row>
              <v-col xl="12" lg="12" md="12" sm="12">
              <b><v-icon>mdi-format-list-bulleted-square</v-icon> Categorias: </b>
              <span v-for="(item, index) in employee.categories" :key="index">
                <span v-if="index != 0">, </span> <span>{{ item.name }}</span>
              </span>
            </v-col>
            <v-col xl="12" lg="12" md="12" sm="12">
              <b><v-icon>mdi-list-status</v-icon> Status:</b> <span v-if="employee.active">Ativo</span><span v-else>Inativo</span>
            </v-col>
              </v-row>
          
        </v-card-text>
        <confirm-dialog
          :dialog="dialogActiveInactive"
          :title="`${employee.active? 'Inativar ': 'Ativar '} ${employee.name}`"
          body="Tem certeza que desenha realizar essa ação?"
          @dialog-event="inactiveActive">
        </confirm-dialog>
        <confirm-dialog
          :dialog="dialogDeleteEmployee"
          :title="`Excluir  ${employee.name}`"
          :body="`Tem certeza que deseja excluir o produto ${employee.name} ?`"
          @dialog-event="deleteEmployee">
        </confirm-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {EMPLOYEE_GET} from "@/store/actions/employees.type";
import {
  EMPLOYEE_RESET,
  EMPLOYEES_RESET,
  SET_EMPLOYEE_ACTIVE,
} from "@/store/mutations/employees.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
import {EMPLOYEE_DELETE, EMPLOYEE_ACTIVE, EMPLOYEE_INACTIVE} from "@/store/actions/employees.type";

import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "employee",

  components: {
    //ModalDelete,
    ConfirmDialog,
  },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(EMPLOYEE_RESET);
    await store.commit(EMPLOYEES_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(EMPLOYEE_RESET),
      store.dispatch(EMPLOYEE_GET, to.params.id),
    ]).then(() => {
      next();
    });
  },
  data: () => ({

    dialogActiveInactive: false,
    dialogDeleteEmployee: false,

  }),
  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },

    deleteEmployee(status) {
      this.dialogDeleteEmployee = false
      if (!status) {
        return
      }
      this.$store.commit(CHANGE_PROGRESS, true)
      this.$store
        .dispatch(EMPLOYEE_DELETE)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Excluído com sucesso";

          this.$router.push({
            name: "employees",
          });
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao excluir.";
          
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
    inactiveActive(status) {
      this.dialogActiveInactive = false
      if (!status) {
        return
      }
      let action = this.employee.active ? EMPLOYEE_INACTIVE : EMPLOYEE_ACTIVE;
      this.$store.commit(CHANGE_PROGRESS, true)

      this.$store
        .dispatch(action, this.employee.id)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Alterado com sucesso";
          this.$store.commit(SET_EMPLOYEE_ACTIVE, !this.employee.active)
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao alterar.";
          
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    }
  },

  computed: {
    ...mapGetters(["employee", "checkUsers", "snackbar"]),
  },
};
</script>
